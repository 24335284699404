import React from 'react';
import Accordion from '../common/Accordion/Accordion';

import { getMessages } from '../../locales';

const FAQSection = () => {
  const messages = getMessages();
  
  return <div className="home-faq container">
    <h3>{messages['faqSection.title']}</h3>
    {messages['faqSection.questions'].map(({ title, answer }, id) => (
      <Accordion key={id} title={title} answer={answer} />
    ))}
  </div>;
};

export default FAQSection;
