import React from 'react';
import { number, string, oneOf } from 'prop-types';

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

const ArrowIcon = ({ width = 15, height = 28, color = 'black', type = DOWN, className }) => {
  const getArrowRotation = (type) => {
    switch (type) {
      case UP:
        return 90;
      case LEFT:
        return 0;
      case RIGHT:
        return 180;
      default:
        return 270;
    }
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ transform: `rotate(${getArrowRotation(type)}deg)`, transformOrigin: '50% 50%' }}
    >
      <path
        d="M0.600001 13.9998L1.58878 15.1189L12.2844 27.1998L15 24.9616L5.29324 13.9998L15 3.03801L12.2844 0.799805L1.58878 12.8807L0.600001 13.9998Z"
        fill={color}
      />
    </svg>
  );
};

ArrowIcon.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string,
  type: oneOf([DOWN, UP, LEFT, RIGHT]),
};

export default ArrowIcon;
