import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from '../common/Button/Button';

import headerIllustration from '../../assets/home/header-illustration.svg';
import useAnalytics from '../../hooks/useAnalytics';

import { getLocale, getMessages } from '../../locales';
import { getPortalUrl } from '../../utils';

const Header = () => {
  const { trackEvent } = useAnalytics();

  const messages = getMessages();
  const locale = getLocale();

  return (
    <section className="home-header container">
      <div className="row align-items-center">
        <div className="content col-sm">
          <h1>{messages['header.title']}</h1>
          <p>{ReactHtmlParser(messages['header.description'])}</p>
          <Button
            buttonStyle="primary"
            size="medium"
            title={messages['header.viewAccount']}
            onClick={() => {
              trackEvent('Click View my Account', { Location: 'header' });
              const portalUrl = getPortalUrl();
              location.href = portalUrl + `?lang=${locale}`;
            }}
            ariaLabel={messages['header.viewAccount']}
          />
        </div>
        <div className="col-sm home-header-image">
          <img
            width="643"
            height="499"
            src={headerIllustration}
            alt={messages['header.altImage']}
          />
        </div>
      </div>
    </section>
  );
};

export default Header;
