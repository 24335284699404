/* eslint-disable react/prop-types */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { getMessages } from '../../locales';

const QuotesSection = ({ quotes, images }) => {
  const messages = getMessages();
  
  return <section className="quotes-section container">
    <div className="row">
      <div className="col-sm-12 quotes-section-description">
        <h3>{messages['quoteSection.title']}</h3>
      </div>
      {quotes.map(({ name, quote, employee }, i) => (
        <div key={name} className="col-md-12 col-lg-4 quote-wrapper">
          <div className="quotes-section-quote">
            {ReactHtmlParser(quote)}
            <div className="about-team-member-author">
              <img
                width="180"
                height="180"
                src={images[i].src.childImageSharp.fluid.src}
                alt={`${name} avatar`}
              />
              <div className="author-desc">
                <h4>{employee}</h4>
              </div>
            </div>
            <span className="author">{name}</span>
          </div>
        </div>
      ))}
    </div>
  </section>
};

export default QuotesSection;
