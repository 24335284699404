import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import imageUrl from '../../assets/home/who-we-are-img.svg';
import Button from '../common/Button/Button';

import { getMessages } from '../../locales';

const WhoWeAre = () => {
  const messages = getMessages();
  
  return (
    <section className="who-we-are container">
      <div className="row align-items-center">
        <div className="who-we-are-image col-sm">
          <img width="714" height="683" src={imageUrl} alt={messages['whoWeAre.imageAlt']} />
        </div>
        <div className="who-we-are-desc col-sm">
          <h2>{messages['whoWeAre.title']}</h2>
          <p>{ReactHtmlParser(messages['whoWeAre.desc'])}</p>
          <Button
            linkTo="/about"
            buttonStyle="secondary"
            size="small"
            title={messages['whoWeAre.button']}
          />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
