import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import leftImageUrl from '../../assets/home/splitScreen-illus-1.svg';
import rightImageUrl from '../../assets/home/splitScreen-illus-2.svg';

import { getMessages } from '../../locales';

const SplitSection = () => {
  const messages = getMessages();
  
  return <section className="home-split-section container">
    <div className="row align-items-center">
      <div className="col-sm">
        <img
          src={leftImageUrl}
          alt={messages['splitSection.flexiblePlanIllus']}
          width="452"
          height="339"
        />
        <h3>{messages['splitSection.flexiblePlanTitle']}</h3>
        <p>{ReactHtmlParser(messages['splitSection.flexiblePlanDesc'])}</p>
      </div>
      <div className="divider hide-sm" />
      <div className="col-sm">
        <img
          width="452"
          height="339"
          src={rightImageUrl}
          alt={messages['splitSection.honestyIllus']}
        />
        <h3>{messages['splitSection.honestyTitle']}</h3>
        <p>{ReactHtmlParser(messages['splitSection.honestyDesc'])}</p>
      </div>
    </div>
  </section>;
};

export default SplitSection;
